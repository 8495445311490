import React, { useEffect, useState, useRef } from 'react';
import useInterval from 'react-useinterval';
import logo from './logo.svg';
import './App.css';

function App() {

  const [textQuery, setTextQuery] = useState("");

  const [ticket, setTicket] = useState("");

  const [result, setResult] = useState("");

  const [queue, setQueue] = useState([]);

  const [showQuery, setShowQuery] = useState(true);
  const [showContactMessage, setShowContactMessage] = useState(false);

  var keyPressed = (e) => {
    setTextQuery(e.target.value);
  }

  var submitQuery = (query) => {
    console.log(query);
    setTextQuery(query);
    fetch(
      "/api/query/", {
      method: 'POST',
      body: JSON.stringify({ text: query })
    }).then((r, e) => {
      return r.json();
    }).then(r => {
      setTicket(r.uid)
    })
  }

  // useInterval(
  //   () => {
  //     fetch('/api/requests/')
  //       .then((r) => r.json())
  //       .then((r) => {
  //         console.log('Response', r);
  //         console.log('Setting queue:', Object.keys(r).join('\n'));
  //         setQueue(Object.keys(r));
  //       })
  //       .catch(() => {
  //         setQueue(Object.keys({}));
  //       });

  //     if (ticket === "") {
  //       return;
  //     }
  //     submitTicket(null);
  //   },
  //   5000
  // )

  var submitTicket = (query) => {
    fetch(
      "/api/status/" + ticket, {
      method: 'GET'
    }).then((r, e) => {
      return r.json()
    }).then(r => {
      setResult(r)
    })
  }

  var submitText = (e) => {
    submitQuery(textQuery);
    e.preventDefault();
  }

  useEffect(() => {

    if (ticket === "") {
      return
    }

    submitTicket(null);

  }, [ticket]);

  const recoverRef = useRef(null);

  const scrollToRecover = () => recoverRef.current && recoverRef.current.scrollIntoView();

  const mechanismRef = useRef(null);

  const scrollToMechanism = () => mechanismRef.current && mechanismRef.current.scrollIntoView();

  const toolRef = useRef(null);

  const scrollToTool = () => toolRef.current && toolRef.current.scrollIntoView();

  const [recoverActive, setRecoverActive] = useState(false);
  const [mechanismActive, setMechanismActive] = useState(false);
  const [toolActive, setToolActive] = useState(false);

  const joinUsRef = useRef(null);
  const scrollToJoinUs = () => joinUsRef.current && joinUsRef.current.scrollIntoView();

  return (
    <div className="App">
      <div className="bar">
        {/* <div className="barLeft">
          <div className="text">
            RECovER
          </div>
          <div className="subtext">
            Records Evaluation for COVID-19 Emergency Research
          </div>
        </div>
        <div className="barRight">
          <img src="/logos/onai.png" />
        </div> */}

        {recoverActive && <div
          key="recover"
          style={{
            textDecoration: "underline",
            textDecorationColor: "orange",
            textDecorationThickness: 5
          }}
          onClick={() => {
            scrollToRecover();
            setRecoverActive(true);
            setMechanismActive(false);
            setToolActive(false);
          }}>
          RECovER
        </div>}
        {!recoverActive && <div
          key="recover"
          onClick={() => {
            scrollToRecover();
            setRecoverActive(true);
            setMechanismActive(false);
            setToolActive(false);
          }}>
          RECovER
        </div>}
        {!mechanismActive && <div
          onClick={() => {
            scrollToMechanism();
            setMechanismActive(true);
            setRecoverActive(false);
            setToolActive(false);
          }}>
          Mechanism
        </div>}
        {mechanismActive && <div
          style={{
            textDecoration: "underline",
            textDecorationColor: "orange",
            textDecorationThickness: 5
          }}
          onClick={() => {
            scrollToMechanism();
            setMechanismActive(true);
            setRecoverActive(false);
            setToolActive(false);
          }}>
          Mechanism
        </div>}
        {!toolActive && <div
          onClick={() => {
            scrollToTool();
            setToolActive(true);
            setMechanismActive(false);
            setRecoverActive(false);
          }}>
          Query
        </div>}
        {toolActive && <div
          style={{
            textDecoration: "underline",
            textDecorationColor: "orange",
            textDecorationThickness: 5
          }}
          onClick={() => {
            scrollToTool();
            setToolActive(true);
            setMechanismActive(false);
            setRecoverActive(false);
          }}>
          Query
        </div>}

      </div >

      <div className="content">
        <div className="recoverBlurb" ref={recoverRef}>
          <img src="/covid.png" />
          <div className="recoverBlurbText">
            <h1>RECovER</h1>
            The RECovER (Records Evaluation for COVID-19 Emergency Research) initiative aims to accelerate
            collaborative research on COVID-19 through access to high quality real-time clinical data across
            multiple institutions. During the COVID-19 pandemic, clinicians and researchers have been confined
            to using local and/or manually assembled datasets to study the impact of comorbidities, pre-existing
            medication use, demographics, and various interventions on disease course. In RECovER, researchers
            can perform queries across all institutions participating in the consortium, without any compromises
            on privacy or security. Researchers at leading American healthcare institutions and universities have
            been supporting this effort and we welcome any hospital or clinic to <a href="#contact" onClick={() => scrollToJoinUs()}>join us</a>.
          </div>
        </div>

        <div className="mechanism" ref={mechanismRef}>
          <div className="fundingFtr">
            <div className="fundingFtrBlurb">
              <h1>
                Mechanism
              </h1>
              Powered by Onai, the RECovER initiative makes use of powerful cryptography
              to enable cross-institutional research without any sacrifice of security.
              Data remains local and private within each participating institution—no
              records ever leave each institution. Software modules from Onai run at
              each institution and jointly construct a single aggregate statistical
              result, without exposing any institution's local results to any other
              institution. This mechanism enables greater security and speed than traditional
              approaches.
            </div>
            <div className="mechanismDiagram">
              <img src="/mechanismDiagram.png" />
            </div>
          </div>
          <div className="logosRow">
            <a href="https://www.onai.com"><img width="100px" src="/logos/onai.png" style={{ padding: "20px" }} /></a>
            <a href="https://nsf.gov"><img width="100px" src="/logos/nsf.png" style={{ padding: "20px" }} /></a>
            <div style={{ paddingRight: 20 }}>
              This work was partly supported with funding from the National Science Foundation.
            </div>
          </div>
          <div ref={joinUsRef} style={{ height: "50px" }}></div>
          <div style={{ textAlign: "left", padding: 20 }}>
            <a href="mailto:info@onai.com">Email</a> us if you are with a healthcare institution,
            anywhere in the world, that is interested in joining this medical records research effort.
            We would be happy to onboard you.
          </div>
        </div>


        <div className="tool" ref={toolRef}>
          <h1>Query</h1>

          {showQuery && <div><form onSubmit={() => { setShowQuery(false); setShowContactMessage(true) }}>
            <input
              className="textInput"
              // placeholder="Data Set Query"
              value={textQuery}
              onChange={keyPressed}
            />

            <button type="button" className="ToDoSubmit" onClick={() => { setShowQuery(false); setShowContactMessage(true) }}>🔍</button>
          </form>

            <div className="examples">
              Example queries:<br />
              <div>
                &bull; {"average length of stay where age > 70"}<br />
                &bull; {"impact of pre-existing blood pressure medication"}<br />
                &bull; {"fraction mechanical ventilation where age < 40"}<br />
              </div>
            </div></div>}

          {showContactMessage && <div style={{ border: "1px solid #cccccc", padding: 100, borderRadius: "5px" }}>
            <a href="mailto:info@onai.com">Email us</a> if you are a researcher/clinician and your healthcare
            institutions or clinical partners are interested in participating.
          </div>
          }
        </div>


        { /*
          <div className="results">
            <div className="resultsForm">
              <form onSubmit={submitTicket}>
                <input
                  className="submitTicket"
                  value={ticket}
                  placeholder="Enter ticket #"
                  onChange={(e) => { setTicket(e.target.value) }}></input>
                <button type="button" className="ToDoSubmit" onClick={submitTicket}>🔍</button>
              </form>
            </div>
            <div className="resultsResp">
              <div className="resultsRespPre">
                {(result.length > 0) && result || <div>Execute a query to see results here</div>}
              </div>
            </div>
          </div>

          <hr />

          <div className="tickets">
            Executed Query Tickets
          <div className="ticketsList">
              {(queue.length > 0) && queue.map((q, i) => <div key={i.toString()} ><a href={`#ticket${i}`} onClick={(e) => { setTicket(q); e.preventDefault(); }}>{q}</a></div>) || <div style={{ color: "gray" }}>Tickets from previously executed queries</div>}
            </div>
          </div> */}
        <hr />

        {/* <div className="bigFtr">
          <div className="img1">
            <img src="/barda_slide-02.png" />
          </div>
          <div className="imgText">
            <div>
              Text about this image
          </div>
            <div>
              More text
          </div>
          </div>
        </div>

        <hr /> */}

        <div style={{ marginBottom: 50 }}>
          &#169; Copyright Onai, 2020
        </div>
      </div>
    </div >
  );
}

export default App;
